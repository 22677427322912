import {Image, Form, Container, Row, Col} from 'react-bootstrap';
import React, {useEffect, useState} from "react";
import {fetchDays} from "../api/fetchDays";
import moment from "moment";
import {
    getCalendarDays,
    getProjects,
    getSelectedProject,
    getUnusedVacationDays,
    getUserName,
    removeDay,
    submitDay,
    submitSelectedProject
} from '../api/ApiRequest';
import {AbsenceDialog} from "./AbsenceDialog";
import {Sidebar} from "./Sidebar";
import "../styles/menuStyle.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/en-gb";
import "../styles/calendar.css";
import "../styles/tooltip.css";
import Modal from 'react-modal';
import {isMobile} from "react-device-detect";
import {Tooltip} from "bootstrap";

Modal.setAppElement('#root');

let tooltipInstance: { show: () => void; dispose: () => void; } | null = null;

const LoggedInPage = props => {
    const [workDates, setWorkDates] = useState({});
    const [eventsDB, setEventsDB] = useState([]);
    const [userId, setUserId] = useState(props.id);
    const [logOutName] = useState(props.logOutName);
    const [projectId, setProjectId] = useState('')
    const [currentName, setCurrentName] = useState('')
    const [paidVacationDays, setPaidVacationDays] = useState(0);
    const [paidVacationDaysInFuture, setPaidVacationDaysInFuture] = useState(0);
    const [researchDayAvailableInMonth, setResearchDayAvailableInMonth] = useState(true);
    const [extraVacationAllowed, setExtraVacationAllowed] = useState(false);
    const [extraVacationDays, setExtraVacationDays] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [modalProjectId, setModalProjectId] = useState('');
    const [modalDayType, setModalDayType] = useState('');
    const [absenceStartDate, setAbsenceStartDate] = useState('');
    const [absenceEndDate, setAbsenceEndDate] = useState('');
    const [monthNr, setMonthNr] = useState('');
    const [yearNr, setYearNr] = useState('');
    const [projects, setProjects] = useState<any[]>([]);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        fetchDays([], setWorkDates, userId);
    }, [userId]);

    useEffect(() => {
        getCalendarDays(userId).then((res) => {
            setEventsDB(res)
        })
    }, [userId])

    useEffect(() => {
        getProjects().then((res) => {
            setProjects(res);
        });
    }, []);

    useEffect(() => {
        getUserName(userId).then((res) => {
            setCurrentName(res.name)
        })
    }, [userId])

    useEffect(() => {
        getSelectedProject(userId).then((projectInfo) => {
            if (projectInfo) {
                setProjectId(projectInfo.projectID)
            } else {
                setProjectId("00")
            }
        })
    }, [userId])

    useEffect(() => {
        getUnusedVacationDays(userId).then((vacationResponse) => {
            setPaidVacationDays(vacationResponse.remainingPaidVacation);
            setPaidVacationDaysInFuture(vacationResponse.plannedPaidVacation);
            setResearchDayAvailableInMonth(vacationResponse.researchDayAvailableInMonth);
            setExtraVacationDays(vacationResponse.remainingExtraVacation);
            setExtraVacationAllowed(vacationResponse.hasExtraVacation);
            initializedReport();
        })
    }, [userId])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const initializedReport = () => {
        let month = new Date().getMonth();
        let year = new Date().getFullYear();
        setMonthNr(String(month + 1));
        setYearNr(String(year));
    }

    const workDateHandler = (day, project) => {
        const date = moment(day).format('YYYY-MM-DD');
        let pickedDate = day;
        let today = new Date();
        let firstOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        if ((today.getDate() > 10 && daysFromDate(pickedDate) >= today.getDate()) || (pickedDate < firstOfPrevMonth)) {
            alert("Working days for a month have to be entered latest by the 10th of the following month")
            return;
        } else {
            if (project === "00") {
                alert("Select a project before submitting work day")
                return;
            }
            submitDay({
                "date": date,
                googleID: userId,
                dayType: 'WORK',
                projectID: project,
                description: ''
            }).then(() => getCalendarDays(userId).then((res) => {
                setEventsDB(res)
            }))

            setWorkDates({
                ...workDates, [date]: {
                    customStyles: {},
                }
            })
        }
    };

    const reportUpdateNew = (firstDateOfGrid) => {
        let date = new Date(firstDateOfGrid);
        date.setDate(date.getDate() + 14);
        let month = date.getMonth();
        let year = date.getFullYear();
        setMonthNr(String(month + 1));
        setYearNr(String(year));
    }

    const absencesDialogHandler = (selectedDay, endDay) => {
        let pickedDate = selectedDay;
        let today = new Date();
        let firstOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        if ((today.getDate() > 10 && daysFromDate(pickedDate) >= today.getDate()) || (pickedDate < firstOfPrevMonth)) {
            alert("Working days for a month have to be entered latest by the 10th of the following month")
            return;
        }
        setModalDayType("WORK")
        setModalProjectId("00")
        setDialogOpen(true)
        setAbsenceStartDate(selectedDay);
        setAbsenceEndDate(endDay);
    }

    const daysFromDate = (pickedDate) => {
        var oneDay = 1000 * 60 * 60 * 24;
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        return Math.round((today.getTime() - pickedDate.getTime()) / (oneDay));
    }

    const selectProject = (project) => {
        setProjectId(project)
        submitSelectedProject({projectID: project, googleID: userId})
    }

    const sendData = (data) => {
        setUserId(data)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <Container style={{
                paddingTop: ((eventInfo.event.title.length > 20 && width < 980) || isMobile) ? 14 : ((eventInfo.event.title.length >= 10 && width < 980) || isMobile) ?
                    14 : 19
                , justifyContent: "center", alignItems: "center", display: "flex"
            }}>
                <Form.Label style={{color: 'white', fontSize: (width < 980 || isMobile) ? 8 : 10, fontStyle: 'italic', fontWeight: 500, whiteSpace: 'normal'}}>
                    {eventInfo.event.title.replace('/', '/ ')}
                </Form.Label>
            </Container>
        )
    }

    const handleMouseEnter = (info) => {
        if (info.event.extendedProps.description !== "" && !isMobile) {
            tooltipInstance = new Tooltip(info.el, {
                title: info.event.extendedProps.description,
                html: true,
                placement: "bottom",
                trigger: "hover",
                container: "body"
            });
            tooltipInstance.show();
        }
    };

    const handleMouseLeave = () => {
        if (tooltipInstance && !isMobile) {
            tooltipInstance.dispose();
            tooltipInstance = null;
        }
    };

    const handleDateSelect = (selectInfo) => {
        let endDate = new Date(selectInfo.endStr)
        endDate.setDate(endDate.getDate() - 1);
        let startDate = new Date(selectInfo.startStr)
        let calendarApi = selectInfo.view.calendar;
        if (startDate.toString() === endDate.toString()) {
            if (projectId) {
                workDateHandler(startDate, projectId);
            }
        } else {
            absencesDialogHandler(startDate, endDate)
        }
        calendarApi.unselect();
    }

    function handleEventClick(clickInfo) {
        let startDate = new Date(clickInfo.startStr)
        let today = new Date();
        let firstOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        if ((today.getDate() > 10 && daysFromDate(startDate) >= today.getDate()) || (startDate < firstOfPrevMonth)) {
            alert("Cannot remove this day");
            return;
        } else {
            let endDate = new Date(clickInfo.endStr);
            endDate.setDate(endDate.getDate() - 1);
            if (startDate.toString() === endDate.toString()) {
                const date = moment(startDate).format('YYYY-MM-DD');
                removeDay({"date": date, googleID: userId});
                let clonedState = Object.assign({}, workDates);
                delete clonedState[date];
                setWorkDates(clonedState)
            }
        }
        clickInfo.remove();
        handleMouseLeave()
    }

    return (
        <Container>
            <Sidebar
                monthNr={monthNr}
                yearNr={yearNr}
                sendData={sendData}
                id={userId}
                logOutName={logOutName}
            />
            <Container style={{ flex: 1, justifyContent: 'center'}}>
                <Container style={{backgroundColor:'#fff', height:44}}>
                    <Row style={{flexDirection:'row', display:'flex', justifyContent:'space-between', alignItems:'center', paddingLeft:10, paddingRight:7, paddingTop:4,
                        borderBottom:1, borderBottomColor:'#efefef', borderBottomStyle:'solid'}}>
                        <Col><Image style={{width: 100, height: 35}}
                                    src={require('../images/smallLogoPurple.png')}/></Col>
                        <Col><Container>
                            <select
                                style={{ borderColor: 'purple',
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    fontSize: 17,
                                    width:200,
                                    fontFamily:'system-ui',
                                    padding:3}}
                                value={projectId}
                                onChange={(event)=>selectProject(event.target.value)}>
                                {projects.map(project => <option key={project.projectId} value={project.projectId} label={project.projectName}/>
                                )}
                            </select>
                        </Container></Col>
                        <Col style={{width: 100, height: 35}}></Col>
                    </Row>
                </Container>
                <Container style={{alignItems: 'center', flexDirection:'row', display:'flex', paddingTop: 7, justifyContent:'center'}}>
                        <Form.Label style={{
                            color: 'purple',
                            fontWeight: 500,
                            fontSize: 20,
                            fontFamily:'system-ui'
                        }}>{currentName.toUpperCase()}</Form.Label>
                </Container>
                <Container style={{flex: 6,
                    marginTop: 10,
                    width: "100%"}}>
                    <div className='demo-app'>
                        <div className='demo-app-main'>
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                locale={esLocale}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'addAbsence'
                                }}
                                height={600}
                                initialView='dayGridMonth'
                                nextDayThreshold='00:00:00'
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                events={eventsDB}
                                select={handleDateSelect}
                                eventContent={renderEventContent}
                                datesSet={(reportData) => reportUpdateNew(reportData.startStr)}
                                fixedWeekCount={false}
                                selectOverlap={false}
                                eventClick={(reportData) => handleEventClick(reportData.event)}
                                customButtons={{
                                    addAbsence: {
                                        text: 'Add absence',
                                        click: function () {
                                            absencesDialogHandler(new Date(), new Date())
                                        },
                                    },
                                }}
                                buttonText={{today: 'Today'}}
                                longPressDelay={0}
                                eventMouseEnter={handleMouseEnter}
                                eventMouseLeave={handleMouseLeave}
                            />
                        </div>
                    </div>
                    <Container style={{alignItems:'center', flexDirection:'column', display:'flex'}}>
                        <Form.Label style={{paddingTop: 20, textAlign: 'center', fontSize: 15, fontFamily:'system-ui'}}> You currently
                            have {paidVacationDays} unused paid vacation days.</Form.Label>
                        <Form.Label style={{paddingTop: 20, textAlign: 'center', fontSize: 15, fontFamily:'system-ui'}}> You
                            have {paidVacationDaysInFuture} paid vacation days planned in the future.</Form.Label>
                        {researchDayAvailableInMonth &&
                            <Form.Label style={{paddingTop: 20, textAlign: 'center', fontSize: 15, fontFamily:'system-ui'}}> You have a research
                                day available
                                this month</Form.Label>}
                        {extraVacationAllowed &&
                            <Form.Label style={{paddingTop: 20, textAlign: 'center', fontSize: 15, fontFamily:'system-ui'}}> You
                                have {extraVacationDays} unused extra vacation days.</Form.Label>}
                    </Container>
                </Container>
                <Container>{
                    (
                        <Container>
                            <AbsenceDialog workDates={workDates}
                                           setWorkDates={setWorkDates}
                                           projectId={modalProjectId}
                                           setProjectId={setModalProjectId}
                                           extraVacationAllowed={extraVacationAllowed}
                                           remainingExtraVacationDays={extraVacationDays}
                                           dayType={modalDayType}
                                           setDayType={setModalDayType}
                                           isDialogOpen={isDialogOpen}
                                           onModalClose={() => {
                                               setDialogOpen(false)
                                           }}
                                           selectedStartDate={absenceStartDate}
                                           selectedEndDate={absenceEndDate}
                                           id={userId}
                                           projects={projects}
                                           setEventsDB={setEventsDB}
                            />
                        </Container>
                    )}
                </Container>
            </Container>
        </Container>
    );
}

export {
    LoggedInPage
}